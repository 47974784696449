<script setup lang="ts">
import { useRoute, useRouter } from "vue-router";
import { onMounted } from "vue";

const router = useRouter();
const route = useRoute();

onMounted(() => {
  // Expose the router and route to the window for remote-app use
  window.globalRouter = router;
  window.globalRoute = route;

  document.documentElement.style.setProperty("--bg-color-spend", "#F3F3F3");
});
</script>

<template>
  <div class="app-background">
    <div
      class="m-auto flex min-h-full max-w-3xl px-2 py-4 sm:p-4 print:max-w-full"
    >
      <router-view :token="$route.params.token" />
    </div>
  </div>
</template>

<style scoped>
.app-background {
  background-color: var(--bg-color-spend);
}
</style>
