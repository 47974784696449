import { defineAsyncComponent } from "vue";

import { SpendPage } from "./pages";

import LoadingSpinner from "@two-ui/components/LoadingSpinner.vue";

export const routes = [
  {
    path: "/:token",
    name: "payout-link-home",
    component: SpendPage,
  },
  {
    path: "/recipient",
    name: "recipient",
    component: async () =>
      defineAsyncComponent({
        loader: () => import("recipient_app/BasePage"),
        loadingComponent: LoadingSpinner,
      }),
    children: [
      {
        path: "",
        name: "recipient-home",
        component: async () =>
          defineAsyncComponent({
            loader: () => import("recipient_app/RecipientHome"),
            loadingComponent: LoadingSpinner,
          }),
      },
    ],
  },
];
